<template>
  <div class="wallet">
    <headbar :title="$t('我的账户')"></headbar>

    <div class="title">{{ $t('币种') }}</div>
    <div class="market">
      <div class="market-item" v-for="item in walletList" :key="item.id">
        <img src="@/assets/img/logo.png" class="market-icon" />
        <div class="market-right">
          <div class="market-row">
            <div>{{ $t(item.cnameZh) }}</div>
            <div class="market-btn">
              <!-- <div class="btn" v-if="item.cz" @click="tolink('Chongzhi')">{{ $t('存入') }}</div> -->
              <div class="btn" v-if="item.tx" @click="tolink('Tixian?cid=' + item.cid)">{{ $t('提取') }}</div>
              <!-- <div class="btn" v-if="item.cid == 3">{{ $t('提取') }}</div> -->
              <div class="btn" v-if="item.zz" @click="tolink('Zhuanzhang?cid=' + item.cid)">{{ $t('转账') }}</div>
              <div class="btn" v-if="item.zh" @click="tolink('Zhuanhuan?cid=' + item.cid)">{{ $t('映射') }}</div>
              <!-- <div class="btn" v-if="item.cid == 6">{{ $t('添加流动性') }}</div> -->
              <div class="btn" v-if="item.cid == 6" @click="tolink('Remove')">{{ $t('移除流动性') }}</div>
              <div class="btn" v-if="item.cid == 6" @click="tolink('Bonus')">{{ $t('分红记录') }}</div>
            </div>
          </div>
          <div class="market-row q-mt-sm">
            <div>{{ $t('可用') }}：{{ item.jine }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import CustomComponent from "@/components/CustomComponent";

export default {
  name: '',
  components: {},
  setup() {
    return {
      us: ref(null),
      walletList: ref([])
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      this.getWallet()
    }
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    getWallet() {
      let _this = this
      _this.$q.loading.show({
        message: _this.$i18n.t("加载中...")
      })
      _this.$request.post(
        "api/Wallets/GetWalletsPageData",
        {
          userid: _this.us.userid
        },
        (res) => {
          _this.$q.loading.hide();
          if (res.data.code == 0) {
            _this.$q.dialog({
              component: CustomComponent,
              componentProps: {
                messages: [res.data.msg],
                persistent: true,
              }
            });
            return
          }
          let data = res.data.data
          _this.walletList = data
          console.log(res)
        }
      )
    }
  }
}
</script>

<style scoped>
.wallet {
  min-height: 100vh;
  padding: 0 10px;
  background-color: #080535;
}

.total {
  padding: 0 20px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  color: #fff;
  background-color: #2f1d51;
}

.total-jine {
  font-size: 22px;
  font-weight: bold;
}

.usdt-icon {
  width: 55px;
}

.title {
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  font-size: 15px;
  color: #fff;
}

.tips {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tips-item {
  padding-left: 20px;
  width: 48%;
  height: 55px;
  display: flex;
  align-items: center;
  border-radius: 27px;
  color: #fff;
  background-color: #282b4a;
}

.tips-icon {
  margin-right: 10px;
  width: 35px;
}

.mt-sm {
  margin-top: 10px;
}

.market-item {
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  border-radius: 10px;
  font-size: 14px;
  background-color: #281b54;
}

.market-row {
  display: flex;
  justify-content: space-between;
}

.market-icon {
  margin-right: 10px;
  width: 50px;
}

.market-right {
  flex: 1;
}

.market-btn {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13px;
}

.btn {
  font-size: 12px;
  margin-left: 5px;
  padding: 2px 14px;
  color: #fff;
  text-align: center;
  background: linear-gradient(90deg, #f56ef9 0, #8a6dff);
  border-radius: 4px;
}
</style>